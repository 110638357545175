import React from 'react';

class Home extends React.Component {
  constructor() {
    super();

    this.state = {
      eventId: 5,
      firstName: null,
      lastName: null,
      emailAddress: null,
      den: null,
      signUpComplete: false,
      carNumber: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = {
      ...this.state
    };

    fetch('/api/index.php', {
      method: 'POST',
      body: JSON.stringify(data)
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      this.setState({
        carNumber: json.participantNumber,
        signUpComplete: true
      });
    }).catch(e => {
      console.log(e);
    });
  }

  handleResetClick(e) {
    e.preventDefault();

    this.setState({
      firstName: null,
      lastName: null,
      den: null,
      signUpComplete: false,
      carNumber: null,
      emailAddress: null
    });
  }

  renderForm() {
    if (this.state.signUpComplete) {
      return null;
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <label htmlFor="firstName">First Name</label>
        <input
          onChange={this.handleChange}
          type="text"
          name="firstName" />
        <label htmlFor="lastName">Last Name</label>
        <input
          onChange={this.handleChange}
          type="text"
          name="lastName" />
        <label htmlFor="den">Den</label>
        <select
          onChange={this.handleChange}
          value={this.state.den || ''}
          name="den"
          id="den">
            <option value=''></option>
            <option value="lion">Lion</option>
            <option value="tiger">Tiger</option>
            <option value="wolf">Wolf</option>
            <option value="bear">Bear</option>
            <option value="webelos I">Webelos I</option>
            <option value="webelos II">Webelos II</option>
        </select>
        <label htmlFor="emailAddress">Email Address (Optional)</label>
        <input
          onChange={this.handleChange}
          type="text"
          name="emailAddress" />
        <input
          className="button"
          type="submit"
          value="Add Racer" />
      </form>
    );
  }

  renderSuccess() {
    if (!this.state.signUpComplete) {
      return null;
    }

    return (
      <div>
        <h3>Your car number is {this.state.carNumber}</h3>
        <input
          className="button"
          type="submit"
          value="Add Another Racer"
          onClick={this.handleResetClick} />
      </div>
    );
  }

  render() {
    return (
        <div className="content">
            <div className="heading">2024 Pinewood Derby Signup</div>
            {this.renderForm()}
            {this.renderSuccess()}
        </div>
    );
  }
}

export default Home;
