import React from 'react';
import { 
  BrowserRouter as Router,
  withRouter,
  Link
 } from "react-router-dom";

class Results extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.match;

    this.state = {
      results: null,
      den: params.den,
      eventData: null,
      eventId: params.eventId,
      participantId: params.participantId,
      sort: 'time'
    };
  }

  componentDidMount() {
    const { eventId } = this.state;

    if (!eventId) {
      fetch('/api/index.php?type=events')
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          eventId: data.events[data.events.length - 1].id
        });
      });      
    }

    let eventInfo = localStorage.getItem('event_' + eventId);
    let eventResults = localStorage.getItem('results_' + eventId);

    if (!eventInfo || !eventResults || eventInfo==='undefined' || eventResults==='undefined') {
      fetch('/api/index.php?type=results&eventId=' + this.state.eventId)
      .then(response => {
        return response.json();
      })
      .then(data => {
        localStorage.setItem('event_' + eventId, JSON.stringify(data.event));
        localStorage.setItem('results_' + eventId, JSON.stringify(data.results));
        
        return data;
      })
      .then(({ event, results }) => {
        this.setState({
          eventData: event,
          results: results
        });
      }).catch(e => {
        console.log(e);
      });
    }
    else {
      this.setState({
        eventData: JSON.parse(eventInfo),
        results:  JSON.parse(eventResults)
      });
    }
  }

  get combinedLaneData() {
    const { results, den } = this.state;

    let sortedResults = results.reduce((acc, dataItem) => {
      let existingItemIndex = acc.findIndex(({ lane }) => lane === dataItem.lane);

      if (existingItemIndex < 0) {
        acc.push({lane: dataItem.lane, time: dataItem.time});
      }
      else {
        acc[existingItemIndex].time += dataItem.time;
      }

      return acc;
    }, []).sort((itemA, itemB) => itemA.time - itemB.time);

    return den ? sortedResults.filter(result => result.den === den) : sortedResults;
  }

  get combinedRacerData() {
    const { results, den, sort } = this.state;

    let sortedResults = results.reduce((acc, dataItem) => {
      let existingItemIndex = acc.findIndex(({ participantId }) => participantId === dataItem.participantId);

      if (existingItemIndex < 0) {
        acc.push({...dataItem});
      }
      else {
        acc[existingItemIndex].time += dataItem.time;
      }

      return acc;
    }, []).sort((itemA, itemB) => itemA[sort] - itemB[sort]);

    return den ? sortedResults.filter(result => result.den === den) : sortedResults;
  }

  renderLaneResults() {
    if (!this.state.results) {
      return null;
    }

    return(
      <table className="resultsTable laneRank">
          <thead>
            <tr>
              <th colSpan="2">Lane Overall Rankings</th>
            </tr>
            <tr>
              <th>Lane</th>
              <th>Overall Time</th>
            </tr>
          </thead>
          <tbody>
            {
              this.combinedLaneData.map(({ lane, time }) => {
                  return (
                      <tr key={lane}>
                          <td>{lane}</td>
                          <td>{time.toFixed(3)}</td>
                      </tr>
                  );
              })
            }
          </tbody>
      </table>
);
  }

  renderTopThree() {
    if (!this.state.results) {
      return null;
    }

    const topThree = this.combinedRacerData.slice(0,3).map((result, index) => {
      return (
        <div>
          <div className="heading">Place</div>
          <div className="heading">Racer Name</div>
          <div className="value">{index + 1}</div>
          <div className="value">{result.name}</div>
          <div className="heading">Den</div>
          <div className="heading">Overall Time</div>
          <div className="value">{result.den}</div>
          <div className="value">{result.time.toFixed(3)}</div>
        </div>
      );
    });

    return (
      <div className="topThree">
        { topThree }
      </div>
    );
  }

  renderTable(data, title) {
    return (
        <table className="resultsTable">
            <thead>
            <tr>
                <th colSpan="4">{title}</th>
              </tr>
              <tr>
                <th class="sticky">Place</th>
                <th class="sticky">Racer Name</th>
                <th class="sticky">Den</th>
                <th class="sticky">Overall Time</th>
              </tr>
            </thead>
            <tbody>
              {
                data.map(({ participantId, den, time, name }, index) => {
                      let link = "/results/individual/" + participantId + "/" + this.state.eventId;
                      return (
                          <tr key={participantId}>
                              <td>{index + 1}</td>
                              <td><Link to={link}>{name}</Link></td>
                              <td>{den}</td>
                              <td>{time.toFixed(3)}</td>
                          </tr>
                      );
                })
              }
            </tbody>
        </table>
    );
  }

  render() {
    if (!this.state.eventData || !this.state.results) {
      return null;
    }

    const { eventData: { name }, results } = this.state;
    let heatData = [...results].sort((resultA, resultB) => resultA.time - resultB.time);

    return (
      <div className="content">
          <div className="heading">{name} Results</div>
          {this.renderTopThree()}
          <div className='results'>
            {this.renderTable(this.combinedRacerData, 'Overall Rankings')}
          </div>
          <div className='results'>
            {this.renderTable(heatData, 'Fastest Individual Times')}
          </div>
          <div className='results'>
            {this.renderLaneResults()}
          </div>
      </div>
    );
  }
}

export default withRouter(Results);
