import './App.css';

import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './components/Home';
import Register from './components/Register';
import Participants from './components/Participants';
import Results from './components/Results';
import IndividualResults from './components/IndividualResults';

class App extends React.Component {
  render() {
    return (
      <Router>
        <div className="app">
          <header>
            <div className="leftMenu"></div>
            <div className="logo">
              <img src={require("./pack201_logo.png")} height="75px" />
            </div>
            <div className="rightMenu"></div>
          </header>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/results/individual/:participantId/:eventId">
              <IndividualResults />
            </Route>
            <Route path={["/results/event/:eventId", "/results/den/:eventId/:den", "/results"]}>
              <Results />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/participants">
              <Participants />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
