import React from 'react';

const DEN_MAP = [
  { key: 'lion', title: 'Lions' },
  { key: 'tiger', title: 'Tigers6' },
  { key: 'wolf', title: 'Wolves' },
  { key: 'bear', title: 'Bears' },
  { key: 'webelos I', title: 'Webelos I' },
  { key: 'webelos II', title: 'Webelos II' }
];

const RACE_SORTING_MAP = {
  lion: 'Lion',
  tiger: 'Tiger',
  wolf: 'Wolf',
  bear: 'Bear',
  'webelos I': 'Webelos I',
  'webelos II': 'Webelos II'
}

function getTime() {
  const date = new Date();

  return date.getTime();
}

function createCSV(data, startId) {
  let csv = '"LastName","FirstName","Group","Subgroup","CarNumber"\n';

  if (data) {
    data.sort(({ rowId }, { rowId: rowIdB }) => rowId - rowIdB).forEach(({ den, firstname, participantId, lastname }) => {
      csv += `"${lastname}","${firstname}","${RACE_SORTING_MAP[den]}","${den}","${participantId}"\n`;
    });
  }

  return 'data:text/csv;charset=utf-8,' + encodeURI(csv);
}

class Participants extends React.Component {
  constructor() {
    super();

    this.state = {
      eventId: 3,
      data: null,
      startId: 1
    };
  }

  componentDidMount() {
    fetch('/api/?type=participants&eventId=5')
      .then(response => {
        return response.json();
      })
      .then(json => {
        this.setState({
          data: json
        });
      }).catch(e => {
        console.log(e);
      });
  }

  renderTable() {
    if (!this.state.data) {
      return null;
    }

    const { startId } = this.state;

    return (
      <table className="participants">
        <tbody>
          {
            DEN_MAP.reduce((acc, { key, title }) => {
              acc.push(
                <tr className="denTitle" key={title}>
                  <td colSpan="3">{title}</td>
                </tr>,
                ...this.state.data.filter(({ den }) => den === key)
                  .map(({ firstname, participantId, rowId, lastname }) => {
                    return (
                      <tr key={rowId}>
                        <td>{participantId}</td>
                        <td>{lastname}</td>
                        <td>{firstname}</td>
                      </tr>
                    );
                  })
              );

              return acc;
            }, [])
          }
        </tbody>
      </table>
    );
  }

  render() {
    const { data, startId } = this.state;
    return (
      <div className="content">
        <div className="heading">2024 Pinewood Derby</div>
        <a
          href={createCSV(data, startId)}
          target="_blank"
          download={`pwd_roster_${getTime()}.csv`}>
          Download CSV
        </a>
        <div className='results'>
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

export default Participants;
