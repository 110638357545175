import React from 'react';
import { withRouter} from "react-router-dom";

class IndividualResults extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.match;
    
    console.log(params);

    this.state = {
      results: null,
      eventData: null,
      eventId: parseInt(params.eventId),
      participantId: parseInt(params.participantId),
      sort: 'heat'
    };
  }

  componentDidMount() {
    const { eventId } = this.state;
    let eventInfo = localStorage.getItem('event_' + eventId);
    let eventResults = localStorage.getItem('results_' + eventId);

    if (!eventInfo || !eventResults) {
      fetch('/api/index.php?type=results&eventId=' + this.state.eventId)
      .then(response => {
        return response.json();
      })
      .then(data => {
        localStorage.setItem('event_' + eventId, JSON.stringify(data.event));
        localStorage.setItem('results_' + eventId, JSON.stringify(data.results));

        return data;
      })
      .then(({ event, results }) => {
        this.setState({
          eventData: event,
          results: results
        });
      }).catch(e => {
        console.log(e);
      });
    }
    else {
      this.setState({
        eventData: JSON.parse(eventInfo),
        results:  JSON.parse(eventResults)
      });
    }
  }

  get sortedData() {
    const { participantId, results } = this.state;

    return results
        .filter(result => result.participantId === participantId)
        .reduce((acc, result) => {
        let place = results.filter(resultObj => result.heat === resultObj.heat && result.den === resultObj.den && result.time > resultObj.time).length + 1;

        acc.push({ ...result, place: place });

        return acc;
        }, [])
        .sort((itemA, itemB) => itemA.heat - itemB.heat);
  }

  renderTable() {
    return (
      <table className="results">
          <thead>
           <tr>
              <th colSpan="4">{this.sortedData[0].name}</th>
            </tr>
            <tr>
              <th>Heat</th>
              <th>Place</th>
              <th>Lane</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {
              this.sortedData.map(({ heat, time, lane, place }) => {
                    return (
                        <tr key={heat}>
                            <td>{heat}</td>
                            <td>{place}</td>
                            <td>{lane}</td>
                            <td>{time.toFixed(3)}</td>
                        </tr>
                    );
              })
            }
          </tbody>
      </table>
    );
  }

  render() {
    if (!this.state.results) {
      return null;
    }

    const { name } = this.state.eventData;

    return (
        <div className="content">
            <div className="heading">{name} Results</div>
            {this.renderTable()}
        </div>
    );
  }
}

export default withRouter(IndividualResults);
